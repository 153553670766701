<template>
  <div class="ele-body">
    <el-tabs >
      <el-card shadow="never">
        <div class="my-headline">订单投诉</div>
        <!-- 搜索表单 -->
        <el-form :model="table.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
                 @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
					<el-form-item label="订单类型:" label-width="76px">
						<el-select v-model="table.where.order_type" class="input150" clearable>
							<el-option value="1" label="巡游出租"></el-option>
							<el-option value="2" label="城际定制"></el-option>
							<el-option value="3" label="帮我买"></el-option>
							<el-option value="4" label="取送件"></el-option>
							<el-option value="5" label="超市"></el-option>
							<el-option value="6" label="叫餐"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否处理:" label-width="80px">
						<el-select v-model="table.where.status" class="input150" clearable>
							<el-option value="0" label="未处理"></el-option>
							<el-option value="1" label="处理中"></el-option>
							<el-option value="2" label="已完成"></el-option>
				<!-- <el-option v-for="item in timeLineList" :key="item.id" :value="item.id" :label="item.timestamp"></el-option> -->
						</el-select>
					</el-form-item>
					<el-form-item label="订单编号:" label-width="80px">
						<el-input v-model="table.where.order_real_id" placeholder="请输入订单编号" class="input150" clearable/>
					</el-form-item>

					<el-form-item label="商家手机号:" label-width="100px">
						<el-input v-model="table.where.driver_phone" placeholder="请输入手机号" class="input150" clearable/>
					</el-form-item>
					<el-form-item label="时间:" label-width="50px">
						<el-date-picker v-model="table.where.update_time" type="datetime" style="width:163px" placeholder="选择日期时间" clearable>
						</el-date-picker>
					</el-form-item>
          <el-form-item label="省:" label-width="35px">
            <el-select v-model="table.where.pid"  @change="handleChangeProv(table.where.pid)" placeholder='请选择省' class="input150" clearable>
              <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市:" label-width="35px">
            <el-select v-model="table.where.cid"  @change="handleChangeCity(table.where.cid),$forceUpdate()" placeholder='请选择市' class="input150" clearable>
              <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="县/区:" label-width="65px">
            <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" class="input150 mr-20" clearable>
              <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
            <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- 数据表格 -->
        <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
          <template slot-scope="{index}">
            <el-table-column type="selection" width="45" align="center" fixed="left"/>
            <el-table-column type="index" :index="index" label="序号" width="60" align="center" fixed="left" show-overflow-tooltip/>

            <el-table-column prop="order_type" label="订单类型"  show-overflow-tooltip min-width="100">
              <template slot-scope="scope">
                {{scope.row.order_type==1?'巡游出租':scope.row.order_type==2?'城际定制':scope.row.order_type==3?'帮我买':scope.row.order_type==4?'取送件':scope.row.order_type==5?'超市':'叫餐'}}
         
              </template>
            </el-table-column>

            <el-table-column prop="order_real_id" label="订单号"  show-overflow-tooltip min-width="180"/>
            <el-table-column prop="set_area" label="地区"  show-overflow-tooltip min-width="240"/>
            <el-table-column prop="user_name" label="用户名"  show-overflow-tooltip min-width="140"/>
            <el-table-column prop="tel" label="用户手机号"  show-overflow-tooltip min-width="120"/>
            <el-table-column prop="business_name" label="商家名称"  show-overflow-tooltip min-width="170"/>
            <el-table-column prop="business_phone" label="商家手机号"  show-overflow-tooltip min-width="120"/>

            <el-table-column prop="content" label="投诉内容"  show-overflow-tooltip min-width="120"/>
            <el-table-column prop="create_time" label="投诉时间"  show-overflow-tooltip min-width="160"/>
            <el-table-column prop="status_name" label="处理状态"  show-overflow-tooltip min-width="160"/>
<!--            <el-table-column prop="status" label="处理状态"  show-overflow-tooltip min-width="130">-->
<!--              <template slot-scope="scope">-->
<!--                <span>{{scope.row.status==0?'未处理':(scope.row.status==1?'处理中':'已处理')}}{{scope.row.rush}}</span>-->
<!--              </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="update_time" label="处理时间"  show-overflow-tooltip min-width="160"/>

            <el-table-column label="操作" width="160px" align="left"   fixed="right">
              <template slot-scope="{row}">
                <el-link :icon="row.status==0?'el-icon-edit':'el-icon-view'" type="primary" :underline="false" @click="edit(row)" >{{row.status==0?'编辑':'查看'}}</el-link>
                <el-link icon="el-icon-alarm-clock" type="primary" :underline="false" @click="rush(row)" v-if="row.status==0">催办</el-link>
              </template>
            </el-table-column>
          </template>
        </ele-data-table>

        <!-- 对话框：查看反馈记录 -->
        <el-dialog v-dialogDrag :title="editForm.status==0?'编辑订单投诉':'查看订单投诉详情'" 
					:visible.sync="dialogFormVisibleViewRecord" width="800px" 
					:destroy-on-close="true" :lock-scroll="false" @closed="[editForm={},$refs.table.reload()]">
          <el-form :model="editForm" ref="editForm" label-width="120px" :rules="editRules">
            <el-card shadow="never">
              <div class="my-headline">订单投诉内容</div>
              <div class="timeLine" style="overflow: hidden;">
                <div class="ul_box">
                  <ul class="my_timeline" ref="mytimeline" style="margin-left: 10px;">
                    <li class="my_timeline_item" v-for="(item,index) in timeLineList" :key="index">
                      <!--圈圈节点-->
                      <div class="my_timeline_node" :class="{active: index <= editForm.status}"></div>
                      <!--线-->
                      <div :style="index<editForm.status?'border-top:2px solid #2DB394':'border-top:2px solid #E4E7ED'"
                           :class="[index!=2?'my_timeline_item_line':'my_timeline_item_line1']" ></div>
                      <!--标注-->
                      <div class="my_timeline_item_content" >
                        {{item.timestamp}}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <el-form-item label="用户名:" class="drop-down-second">
                <el-input v-model="editForm.user_name"   disabled class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="手机号:" class="drop-down-second">
                <el-input v-model="editForm.tel"   disabled class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="地区:" class="drop-down-second">
                <el-input v-model="editForm.set_area" disabled class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="投诉时间:" class="drop-down-second">
                <el-input v-model="editForm.create_time" disabled class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="订单号:" class="drop-down-second">
                <el-input v-model="editForm.order_ids"  disabled class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="投诉类型:" class="drop-down-second">
                <el-input v-model="editForm.u_type"  disabled class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="投诉内容:" class="drop-down-second" >
                <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10}" v-model="editForm.content" disabled placeholder="投诉内容" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="图片:" class="drop-down-second aa d-flex" v-if="editForm.imgList!=0">
                 <el-image  v-for="(option, index) in editForm.imgList" :key="index" style="width:100px;height:100px;margin-right:10px" :src="option" fit="cover" :preview-src-list="editForm.imgList"></el-image>
              </el-form-item>
              <el-form-item label="处理结果:" class="drop-down-second" prop="status">
                <el-radio-group v-model="status">
                  <el-radio :label="0">未处理</el-radio>
                  <el-radio :label="1">已处理</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="回复用户:" class="drop-down-second" prop="reply">
                <el-input  type="textarea" :rule="editRules" :autosize="{ minRows: 5, maxRows: 4}" v-model="editForm.reply" :disabled="editForm.status==0?false:true" placeholder="请输入投诉回复内容(回复用户)" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="反馈商家(司机):" class="drop-down-second" prop="follow_message">
                <el-input type="textarea" :rule="editRules" :autosize="{ minRows: 5, maxRows: 4}" v-model="editForm.follow_message" :disabled="editForm.status==0?false:true" placeholder="请输入投诉反馈内容(反馈商户或者司机)" class="w-600"></el-input>
              </el-form-item>
            </el-card>
          </el-form>
          <div class="d-flex f-end" v-if="editForm.status==0?true:false"  slot="footer">
            <el-button @click="dialogFormVisibleViewRecord=false">取消</el-button>
            <el-button type="primary" @click="save()">确定提交</el-button>
          </div>
        </el-dialog>
      </el-card>
    </el-tabs>
     <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "../../../components/footer"
export default {
  name: "SysUser",
  components:{Footer},
  data() {
    return {
      timeIndex:1,
      timeLineList: [{
				id:0,
						timestamp: '未处理',
					}, {
				id:1,
						timestamp: '处理中',
					}, {
				id:2,
						timestamp: '已完成',
      },],
      table: {url: '/customer/order_complain_list', where: {}},
      choose: [],  // 表格选中数据
      editForm: {},  // 表单数据
      checkForm:{},
      provArr:[],
      cityArr:[],
      districtArr:[],
      urgentList:[],
      imageUrl:false,
      headImg:'',
      dialogFormVisibleAddActivity:false,
      dialogVisibleRecord:false,
      dialogFormVisibleViewRecord:false,
      status:'',
      row:[
          {
            state:'开启'
          },
          {
            state:'关闭'
          }
      ],
      radioResult:'1',
      editRules: { // 表单验证规则
        reply: [{
          required: true,
          message: '请输入回复用户的内容',
          trigger: 'blur'
        }],
        follow_message: [{
          required: true,
          message: '请输入回复商户的内容',
          trigger: 'blur'
        }]
      },
    }


  },
  created(){
    this.$http.get('/common/province_list').then(res=>{
      let data=JSON.parse(res.data)
      this.provArr=data
    })
    //console.log(this.table)
  },

  computed: {
    ...mapGetters(["permission"]),
  },
  mounted() {

  },
  methods:{
    edit(row){
      this.dialogFormVisibleViewRecord=true
      this.status=row.status
      row.imgList=row.images.split(',')
      this.editForm=row
    },
    /**
     *选择省
     **/
    handleChangeProv(e){
      /** 获取被选省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table.where.cid=''
        this.table.where.aid=''



      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table.where.aid=''
      })
			}else{
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
    },
    rush(row){
      this.$confirm('确认催办该投诉订单?', '提示', {type: 'warning'}).then(() => {
        const loading = this.$loading({lock: true});
        this.$http.post('/customer/complain_urge', {id: row.id}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.table.rush='---已催办'
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      })
    },
    save() {
			this.$refs['editForm'].validate((valid) => {
				if (valid) {
					this.editForm.status = this.status
					if (this.editForm.status == 1) {
						let editForm = {
							id: this.editForm.id,
							uid: this.editForm.uid,
							order_id: this.editForm.order_id,
							aid: this.editForm.aid,
							reply: this.editForm.reply,
							follow_message: this.editForm.follow_message,
							status: this.editForm.status
						}
						const loading = this.$loading({lock: true});
						this.$http.post('/customer/choose_complain', editForm).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.showEdit = false;
								this.$message({type: 'success', message: res.data.msg});
								this.dialogFormVisibleViewRecord=false
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}else{
						this.$message({type: 'warning', message:'请勾选处理结果为已处理~'});
					}
				} else {
					return false;
				}
			});
    }
  },
}
</script>

<style scoped>
  .drop-down-second{
    min-width:720px;
  }
.ele-block .el-upload-dragger {
  width: 100%;
}
.changeStyle{
  width:150px;margin-right:30px;
}
.ele-body{
  padding:15px;
}
.el-upload-list el-upload-list--text{display:none !important}
.user-info-tabs .el-tabs__nav-wrap {
  padding-left: 20px;
}
.my_timeline_prev, .my_timeline_next {
  float: left;
  display: inline-block;
  background-color: #fff;
  cursor: pointer;
}
.my_timeline_prev {
  width: 200px;
  float: left;
}
.my_timeline_next {
  width: 34px;
  margin-left: -22px;
}
.ul_box {
  width: 700px;
  height: 60px;
  display: inline-block;
  float: left;
  margin-top: 2px;
  overflow: hidden;
  margin-left:140px
}
.my_timeline_item {
  display: inline-block;
  width: 220px;
}
.my_timeline_node {
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
  width: 14px;
  height:14px;
  background:#E4E7ED;
}
.my_timeline_node.active {
  /*background-color: #fff !important;*/
  /*border: 6px solid #f68720;*/
  background:#2DB394
}
.my_timeline_item_line {
  width: 100%;
  height: 10px;
  margin: -8px 0 0 14px;
  border-top: 2px solid #E4E7ED;
  border-left: none;
}
.my_timeline_item_line1 {
  width: 100%;
  height: 10px;
  margin: -8px 0 0 14px;
  border-top: 2px solid #fff !important;
  border-left: none;
}
.my_timeline_item_content {
  margin: 10px 0 0 -10px;
  color:rgb(153,153,153);
  font-size:14px
}
.aa /deep/.el-form-item__content{margin-left:0 !important;}
/deep/.el-dialog{
		margin-top: 30px !important;
	}
</style>

